<template>
  <!-- <v-snackbar v-model="show" :top="top" :color="color">
    {{ message }}
    <v-btn text color="accent" @click.native="show = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar> -->

  <v-snackbar
    v-model="show"
    :timeout="timeout"
    :color="color"
    :top="top"
    :centered="centered"
    :bottom="bottom"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="show = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      top: false,
      centered: false,
      bottom: false,
      message: "",
      color: "",
      timeout: 5000
    };
  },

  created() {
    this.$store.watch(
      state => state.snackbar.snack,
      () => {
        const snack = this.$store.state.snackbar.snack;
        if (snack.message) {
          this.message = snack.message;
          this.timeout = snack.timeout;
          this.color = snack.color;
          this.top = snack.top;
          this.centered = snack.centered;
          this.bottom = snack.bottom;

          this.show = true;

          this.$store.commit("snackbar/SET_SNACK", {});
        }
      }
    );
  }
};
</script>
