<template>
  <div class="item">
    <v-card class="content">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-card-title v-bind="attrs" v-on="on" class="title">{{
            title
          }}</v-card-title>
        </template>
        <span>{{ tooltip || title }}</span>
      </v-tooltip>
      <v-carousel
        cycle
        hide-delimiter-background
        show-arrows-on-hover
        height="125px"
        hide-delimiters
      >
        <v-carousel-item v-for="(stat, i) in stats" :key="i">
          <v-sheet color="blue" height="100%">
            <v-card-text>
              <div class="person">
                #{{ stat.rank }} {{ stat.firstName }}
                {{ stat.lastName }}
              </div>
              <div class="data">
                Points: {{ stat.points }}
                <br />
                Percentage:
                {{ Math.round(stat.percentage * 1000) / 10 }}
                %
              </div>
            </v-card-text>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: false
    }
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.item {
  width: 25%;
  /* min-width: 24%; */
  /* max-width: 26%; */
  /* flex: 1 1 0; */
  /* padding: 10px; */
  /* margin: 10px; */
}

.content {
  margin: 10px;
}

.title {
  border-bottom: 2px solid grey;
  white-space: nowrap;
  overflow: hidden;
}

.person {
  font-size: 2em;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid grey;
  white-space: nowrap;
  overflow: hidden;
}

.data {
  padding-top: 8px;
}

@media (max-width: 1264px) {
  .item {
    width: 33.3%;
    /* min-width: 31%; */
    /* max-width: 34%; */
    /* flex: 0 0 33.3%; */
  }
}

@media (max-width: 960px) {
  .item {
    width: 50%;
    /* min-width: 48%; */
    /* max-width: 51%; */
    /* flex: 1 1 45%; */
    /* flex: 0 0 50%; */
    /* max-width: 48%; */
  }
}

@media (max-width: 600px) {
  .item {
    width: 100%;
    /* flex: 0 0 100%; */
    /* max-width: 100%; */
  }
}
</style>
