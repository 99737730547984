<template>
  <v-app>
    <Keypress key-event="keyup" :key-code="191" @success="keyboardEvent" />
    <Snackbar />
    <SiteNavigation />

    <v-main>
      <v-container fluid>
        <!-- Load and display pages using vue-router-->
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SiteNavigation from "@/components/SiteNavigation.vue";
import { Auth, Hub } from "aws-amplify";
import Snackbar from "@/components/Snackbar.vue";

export default {
  components: {
    SiteNavigation,
    Snackbar,
    Keypress: () => import("vue-keypress")
  },
  methods: {
    keyboardEvent() {
      this.$root.$emit("search");
    }
  },

  beforeCreate() {
    Hub.listen("auth", data => {
      if (data.payload.event === "signIn" || data.payload.event === "signOut") {
        this.$store.dispatch("auth/authChange", {
          user: data.payload.data,
          authState: data.payload.event
        });

        this.$router.push("/").catch(() => {});
      }
    });

    Auth.currentAuthenticatedUser()
      .then(user => {
        this.$store.dispatch("auth/authChange", {
          user: user,
          authState: "signIn"
        });
      })
      .catch(() => {
        this.$store.dispatch("auth/authChange", {
          user: null,
          authState: "signOut"
        });
      });
  }
};
</script>

<style>
@import "./styles/tableStyle.css";
</style>
