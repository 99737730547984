export const namespaced = true;

export const state = {
  snack: {}
};

export const mutations = {
  SET_SNACK(state, showSnack) {
    state.snack = { ...showSnack };
  },
  SET_SNACK_PREDEFINED(state, showSnack) {
    switch (showSnack) {
      case "success1":
        state.snack = {
          message: "Saved successfully.",
          color: "success",
          timeout: 5000,
          top: true
        };
        break;

      case "validation1": {
        state.snack = {
          message:
            "Looks like you missed something.  Verify you have entered all information correctly.",
          color: "warning",
          timeout: 7000,
          top: true
        };
        break;
      }

      case "error1": {
        state.snack = {
          message: "oops.  Looks like something went wrong.",
          color: "error",
          timeout: 8000,
          top: true
        };
      }
    }
  }

  //   resetState(state) {
  //     Object.assign(state, getDefaultState());
  //   }
};
