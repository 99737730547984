import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import titleMixin from "@/mixins/titleMixin";

import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

import * as FullStory from "@fullstory/browser";

Amplify.configure(awsconfig);

Vue.config.productionTip = false;
Vue.mixin(titleMixin);

FullStory.init({ orgId: "17CV3N" });
Vue.prototype.$FullStory = FullStory;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
