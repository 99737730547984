import Vue from "vue";
import Vuex from "vuex";

import * as auth from "./modules/authentication.js";
import * as champions from "./modules/champions.js";
import * as player from "./modules/player.js";
import * as playerStat from "./modules/playerStat.js";
import * as snackbar from "./modules/snackbar.js";

Vue.use(Vuex);

export default new Vuex.Store({
  // strict: true,
  modules: {
    auth,
    champions,
    player,
    playerStat,
    snackbar
  }
});
