export default {
  bestSeasonAllTime() {
    return {
      sql: `Select 
                      'Best Seasons All-Time' title,
                      'Most Points Earned in a Season.  Official Standings.' tooltip,
                      firstName,
                      lastName,
                      bestSeasonRank 'rank',
                      official_pickCorrect points,
                      official_pickCorrectPercentage percentage
                      from (
                          select p.firstName, 
                              p.lastName,
                              r. *,
                              rank() over (order by official_pickCorrect desc) as bestSeasonRank
                          from v_resultBySeason_all r 
                              join players p on r.playerId = p.playerId
                          order by official_pickCorrect desc, season desc
                          limit 20
                          ) data
                      where bestSeasonRank <= 10`
    };
  },

  pointsAllTime() {
    return {
      sql: `Select
                  'Most Points All-Time' title,
                  'Points earned for entire career.  Official Standings.' tooltip,
                  firstName,
                          lastName,
                          career_official_pickCorrectRank 'rank',
                          career_official_pickCorrect points,
                          career_official_pickCorrectPercentage percentage
                      From (
                          select 
                            p.firstName,
                              p.lastName,
                              r.*
                          from v_resultByCareer r
                            join players p on r.playerId = p.playerId
                          where career_official_pickCorrectRank <= 10
                          order by career_official_pickCorrectRank
                ) data`
    };
  },

  careerPickPercentage() {
    return {
      sql: `Select
                  'Best Career Pick Percentage*' title,
                  'Minimum 257 picks made.  Unofficial percentage.' tooltip,
                  firstName,
                  lastName,
                  career_pickCorrectPercentageRankFiltered 'rank',
                  career_pickCorrect points,
                  career_pickCorrectPercentage percentage
              From (
                  select 
                    p.firstName,
                      p.lastName,
                      r.*,
                      rank() over (order by career_pickCorrectPercentageRank) career_pickCorrectPercentageRankFiltered
                  from v_resultByCareer r
                    join players p on r.playerId = p.playerId
                  where career_pickCorrect + career_pickIncorrect > 256 
                  order by career_pickCorrectPercentageRank
              ) data
              where career_pickCorrectPercentageRankFiltered <= 10`
    };
  }
};
