<template>
  <div></div>
</template>

<script>
// import { POINT_MARKER_ICON_CONFIG } from "@/constants/mapSettings";

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    }
  },

  mounted() {
    const { Marker } = this.google.maps;

    new Marker({
      // position: this.marker.position,
      position: { lat: this.marker.lat, lng: this.marker.lng },
      marker: this.marker,
      map: this.map
      // icon: POINT_MARKER_ICON_CONFIG
      // icon: "{url: require('../../assets/beachflag.png')}"
      // icon: "{ url: require('../assets/beachflag.png?raw=true')}"
      // icon: "../assets/beachflag.png"
      // icon: "https://azfbf.s3-us-west-2.amazonaws.com/img/1.06adb4db.png"
      // icon:
      // "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
    });
  }

  // render() {}
};
</script>
