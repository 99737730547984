<template>
  <v-card>
    <v-card-title>{{ statistic.title }}</v-card-title>

    <v-progress-circular
      v-if="loading"
      color="primary"
      indeterminate
      :width="1"
      :size="22"
      class="ml-5 mb-2"
    ></v-progress-circular>

    <v-card-text class="display-2">{{ calculatedValue }} </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    statistic: {
      type: Object,
      required: true
    }
  },
  computed: {
    loading() {
      if (this.statistic && this.statistic.value !== "") return false;

      return true;
    },

    calculatedValue() {
      if (this.statistic.value == null) {
        return 0;
      } else {
        return this.statistic.value;
      }
    }
  }
};
</script>

<style></style>
