import EventService from "@/services/EventService.js";

export const namespaced = true;

export const state = {
  weekWinner: [],
  seasonStat: [],
  promiseWeekWinner: null,
  promiseSeasonStat: null
};

export const mutations = {
  SET_WEEK_WINNER(state, data) {
    for (var i = 0; i < data.length; i++) {
      state.weekWinner.push(data[i]);
    }
  },
  SET_SEASON_STATS(state, data) {
    state.seasonStat = data;
  },
  SET_PROMISE(state, data) {
    state[data.field] = data.value;
  },

  CLEAR_SEASON_STAT(state) {
    state.seasonStat = [];
  }
};

export const actions = {
  fetchWeekWinner({ state, commit }, id) {
    //If data is already set, no need to pull it again
    if (state.weekWinner && state.weekWinner.length > 0) {
      return;
    }

    // If the promise is set another request is already getting the data.  return the first requests promise and exit
    if (state.promiseWeekWinner) {
      return state.promiseWeekWinner;
    }

    //Pull Data
    var promise = EventService.weekWinner(id)
      .then(response => {
        commit("SET_WEEK_WINNER", response.data);
        commit("SET_PROMISE", { field: "promiseWeekWinner", value: null });
      })
      .catch(error => {
        console.log("There was an errors:", error.response);
        commit("SET_PROMISE", { field: "promiseWeekWinner", value: null });
      });

    //While data is being async gathered via Axios we set this so that subsequent requests will exit above before trying to fetch data multiple times
    commit("SET_PROMISE", { field: "promiseWeekWinner", value: promise });

    return promise;
  },

  fetchPlayerSeasonStat({ commit }, id) {
    //   If the player being searched for is already in state, we can exit.  Not other data to get.
    if (state.seasonStat.findIndex(player => player.PlayerID === id) != -1) {
      return;
    }

    // If the promise is set another request is already getting the data.  return the first requests promise and exit
    if (state.promiseSeasonStat) {
      return state.promiseSeasonStat;
    }

    //We clear here so that when page is loading it does not breifly show last players info
    commit("CLEAR_SEASON_STAT");

    //Pull Data
    var promise = EventService.getPlayerSeasonStats(id)
      .then(response => {
        commit("SET_SEASON_STATS", response.data);
        commit("SET_PROMISE", { field: "promiseSeasonStat", value: null });
      })
      .catch(error => {
        console.log("There was an error:", error.response);
        commit("SET_PROMISE", { field: "promiseSeasonStat", value: null });
      });

    //While data is being async gathered via Axios we set this so that subsequent requests will exit above before trying to fetch data multiple times
    commit("SET_PROMISE", { field: "promiseSeasonStat", value: promise });

    return promise;
  }
};

export const getters = {
  getIndexByPlayerId: state => id => {
    return state.weekWinner.findIndex(player => player.playerId === id);
  },
  getWeekWinnerById: state => id => {
    let x = state.weekWinner.filter(player => player.playerId === id);
    return x;
  }
};
