<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PlayerHeader :id="id" @edit="playerEdit = true" />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="(stat, i) in infoCardStat" :key="i" cols="12" sm="6" md="3">
        <InfoCard :statistic="stat" />
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col
        v-for="(stat, i) in sparklineStats"
        :key="i"
        cols="12"
        sm="6"
        md="4"
      >
        <StatSparkline :stat="stat" />
      </v-col>
    </v-row> -->

    <v-row>
      <v-col style="max-height:3400px; overflow-y:scroll" cols="4">
        <PlayerWinTimeline :id="id" />
      </v-col>

      <v-col cols="8">
        <v-row>
          <v-col>
            <PlayerBio :id="id" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <PlayerSeasonStats :id="id" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <PlayerStatsTrends :id="id" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <PlayerEdit
      v-if="playerEdit"
      :id="id"
      @close="playerEdit = false"
    ></PlayerEdit>

    <!-- <v-btn
      v-if="loggedIn"
      @click="playerEdit = true"
      color="info"
      style="position:absolute; top:15px; right: 15px"
      ><v-icon x-large>mdi-account-edit</v-icon></v-btn
    > -->
  </v-container>
</template>

<script>
import InfoCard from "@/components/InfoCard.vue";
// import StatSparkline from "@/components/StatSparkline.vue";
import PlayerSeasonStats from "@/components/Player_SeasonStats.vue";
import PlayerWinTimeline from "@/components/Player_WinTimeline.vue";
import PlayerHeader from "@/components/Player_Header.vue";
import PlayerBio from "@/components/Player_Bio.vue";
import PlayerEdit from "@/components/Player_Edit";
import PlayerStatsTrends from "@/components/Player_StatsTrends.vue";

// import statisticsData from "../data/statistics.json";
import salesData from "../data/sales.json";
import { authComputed } from "@/store/helpers.js";
import _ from "lodash";

export default {
  title: "AZFBF - Players",
  props: ["id"],
  components: {
    InfoCard,
    // StatSparkline,
    PlayerSeasonStats,
    PlayerWinTimeline,
    PlayerHeader,
    PlayerBio,
    PlayerEdit,
    PlayerStatsTrends
  },
  data() {
    return {
      sales: salesData,
      playerEdit: false,
      test: false
    };
  },
  computed: {
    ...authComputed,
    infoCardStat() {
      return this.$store.getters["player/getInfoCardStat"](this.id);
    },

    sparklineStats() {
      var seasonStats = this.$store.state.playerStat.seasonStat;
      seasonStats = _.orderBy(seasonStats, "season");

      var values = [];
      var labels = [];

      seasonStats.forEach(element => {
        values.push(element.official_playerRank);
        labels.push(element.season.toString().substr(-2));
      });

      var stats = [
        {
          title: "Rank",
          values: values,
          color: "blue darken-4",
          labels: labels
        },
        {
          title: "Coming Soon",
          values: [
            325,
            123,
            273,
            573,
            123,
            273,
            573,
            123,
            273,
            573,
            123,
            273,
            573,
            123,
            273,
            573
          ],
          color: "teal",
          labels: [
            "Week 1",
            "2",
            "3",
            "4",
            "2",
            "3",
            "4",
            "2",
            "3",
            "4",
            "2",
            "3",
            "4",
            "2",
            "3",
            "4"
          ]
        },
        {
          title: "Coming Soon",
          values: [746, 415, 943, 1372, 983, 651, 837, 639],
          color: "green",
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"]
        }
      ];

      return stats;
      // return this.sales;
    }
  },
  methods: {
    fetchData() {
      this.$store.dispatch("player/fetchPlayer", this.id);
      this.$store.dispatch("playerStat/fetchPlayerSeasonStat", this.id);
    }
  },

  created() {
    this.fetchData();
  },

  watch: {
    "$route.params.id": function() {
      this.fetchData();
    }
  }
};
</script>

<style></style>
