export const namespaced = true;

export const state = {
  user: undefined,
  authState: undefined
};

export const mutations = {
  SET_USER(state, data) {
    state.user = data.user;
    state.authState = data.authState;
  }
};

export const actions = {
  authChange({ commit }, authData) {
    // console.log(authData);
    commit("SET_USER", authData);
  }
};

export const getters = {
  loggedIn(state) {
    if (!!state.user && state.authState === "signIn") return true;

    return false;
  }
};
