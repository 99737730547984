import EventService from "@/services/EventService.js";
// import Vue from "vue";

export const namespaced = true;

export const state = {
  players: [],
  promise: null
};

export const mutations = {
  ADD_PLAYER(state, player) {
    // Vue.set(state.players,)
    // Vue.state.players.push(player[0]);
    state.players.push(player[0]);
  },
  UPDATE_PLAYER(state, { player, key, value }) {
    player[key] = value;
  },
  SET_PROMISE(state, data) {
    state.promise = data;
  },
  CLEAR_PROMISE(state, data) {
    state.promise = data;
  }
};

export const actions = {
  fetchPlayer({ state, commit, getters }, id) {
    //   If the player being searched for is already in players array, no other data to get, exit
    if (getters.getIndexByPlayerId(id) != -1) {
      return;
    }

    // If the promise is set another request is already getting the data.  return the first requests promise and exit
    if (state.promise) {
      return state.promise;
    }

    //We need to fetch data on current player
    var promise = EventService.getPlayer(id)
      .then(response => {
        commit("ADD_PLAYER", response.data);
        commit("CLEAR_PROMISE", null);
      })
      .catch(error => {
        console.log("There was an error:", error.response);
        commit("CLEAR_PROMISE", null);
      });

    //While data is being async gathered via Axios we set this so that subsequent requests will exit above before trying to fetch data multiple times
    commit("SET_PROMISE", promise);

    return promise;
  }
};

export const getters = {
  getIndexByPlayerId: state => id => {
    return state.players.findIndex(plr => plr.playerId === id);
  },
  getPlayerById: state => id => {
    return state.players.find(plr => plr.playerId === id);
  },
  getInfoCardStat: (state, getters) => id => {
    var me = getters.getIndexByPlayerId(id);

    if (me != -1) {
      var result = [
        {
          title: "Seasons",
          value: state.players[me].seasons
        },
        {
          title: "Championships",
          value: state.players[me].championships
        },
        {
          title: "Wins",
          value: state.players[me].weekWins
        },
        {
          title: "Rookie",
          value: state.players[me].startYear
        }
      ];
    } else {
      result = [
        {
          title: "Seasons",
          value: ""
        },
        {
          title: "Championships",
          value: ""
        },
        {
          title: "Wins",
          value: ""
        },
        {
          title: "TBD",
          value: ""
        }
      ];
    }

    return result;
  }
};
