<template>
  <v-card :loading="loading">
    <span v-if="loading"> Loading Bio ... </span>
    <v-container v-if="!loading && player.note != null">
      <v-row>
        <v-col cols="12">
          <v-card-title>About {{ player.firstName }} </v-card-title>

          <v-card-text class="multiline">
            <span v-if="player.note.length > 800 && !readMoreText">
              <!-- <span v-if="player.Note.length > 200"> -->
              {{ shorten(player.note, 800) }}
            </span>
            <span v-else>{{ player.note }}</span>

            <v-btn
              v-if="player.note.length > 800"
              class="centerBtn"
              color="blue darken-2"
              text
              small
              @click="readMoreText = !readMoreText"
            >
              {{ readMoreText ? "Show Less" : "Show More" }}
            </v-btn>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!loading && player.note == null"
      ><v-row>
        <v-col cols="12">
          <v-card-title
            >About {{ player.firstName }} {{ player.lastName }}</v-card-title
          >
          <v-card-text>No information available</v-card-text>
        </v-col></v-row
      >
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      readMoreText: false
    };
  },
  computed: {
    player() {
      return this.$store.getters["player/getPlayerById"](this.id);
    },
    loading() {
      // if (this.player.Note === null) {
      //   alert("hull");
      //   return true;
      // }
      return this.player ? false : true;
      // return false;
    }
  },
  methods: {
    shorten: function(string, len) {
      return string.substring(0, len + string.substring(len - 1).indexOf(" "));
    }
  },
  created() {
    this.$store.dispatch("player/fetchPlayer", this.id);
  }
};
</script>

<style scoped>
.multiline {
  white-space: pre-wrap;
}

.centerBtn {
  /* position: absolute; */
  width: 100%;
  margin: 0 auto;
  /* right: 40%; */
}
</style>
