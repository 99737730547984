import axios from "axios";

const apiClient = axios.create({
  //   baseURL: `http://localhost:3000`,
  baseURL: `https://u5dobh397k.execute-api.us-west-2.amazonaws.com`,
  withCredentials: false, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

const googleClient = axios.create();

export default {
  getGeocode(value) {
    return googleClient.get(value);
  },
  getChampions() {
    return apiClient.get("/champions");
  },
  getPlayersAll() {
    return apiClient.get("/playersAll");
  },
  getPlayer(id) {
    return apiClient.get("/player?playerId=" + id);
  },
  getPickedHome(id, seasonMin, seasonMax) {
    return apiClient.get(
      "/pickedHomeTeam?playerId=" +
        id +
        "&seasonMin=" +
        seasonMin +
        "&seasonMax=" +
        seasonMax
    );
  },
  getMapMarkers() {
    return apiClient.get("/mapMarkers");
  },
  getTeams() {
    return apiClient.get("/teams");
  },
  getPlayerSeasonStats(id) {
    return apiClient.get("/playerSeasonStats?playerId=" + id);
  },
  getPickFrequency(id, seasonMin, seasonMax) {
    return apiClient.get(
      "/pickFrequency?playerId=" +
        id +
        "&seasonMin=" +
        seasonMin +
        "&seasonMax=" +
        seasonMax
    );
  },
  updatePlayerData(parameters) {
    return apiClient.get("/updatePlayerData", { params: parameters });
  },
  importValidateDataInput(homeTeam, awayTeam) {
    return apiClient
      .get(
        "/importValidateDataInput?homeTeam=" +
          homeTeam +
          "&awayTeam=" +
          awayTeam
      )
      .catch(error => Promise.reject(error));
  },

  import_insertSchedule(season, week, homeTeam, awayTeam) {
    return apiClient
      .get(
        "/import_insertSchedule?season=" +
          season +
          "&week=" +
          week +
          "&homeTeam=" +
          homeTeam +
          "&awayTeam=" +
          awayTeam
      )
      .catch(error => Promise.reject(error));
  },

  import_picks_validateUsers() {
    return apiClient
      .get("/import_picks_validateUsers")
      .catch(error => Promise.reject(error));
  },

  import_picks_validateSchedule(season, week) {
    return apiClient
      .get("/import_picks_validateSchedule?season=" + season + "&week=" + week)
      .catch(error => Promise.reject(error));
  },

  import_picks_insert(season, week) {
    return apiClient
      .get("/import_picks_insert?season=" + season + "&week=" + week)
      .catch(error => Promise.reject(error));
  },

  importRefreshPicksAll(season) {
    return apiClient.get("/importRefreshPicksAll?season=" + season);
  },

  weekWinner(id) {
    return apiClient.get("/weekWinner?PlayerID=" + id);
  },

  article_query() {
    return apiClient.get("/article_query");
  },

  article_insert(article) {
    return apiClient.get("/article_insert", {
      params: article
    });
  },

  article_update(article) {
    return apiClient.get("/article_update", {
      params: article
    });
  },

  game_insert(params) {
    return apiClient.get("/game_NFLDivisions_insert", {
      params: params
    });
  },

  //Should this be removed?  Security?
  customQuery(sql) {
    // pass object to this function with sql field:  Ex {sql:  "Select * from players"}
    return apiClient.get("/querySql", { params: sql });
  }
};
