<template>
  <v-dialog v-model="dialog" persistent max-width="700">
    <v-progress-circular
      v-if="processing"
      class="processing"
      indeterminate
      color="primary"
    ></v-progress-circular>

    <v-card :loading="loading" width="700" class="mx-auto ">
      <v-container v-if="!loading">
        <v-card-title>
          <v-icon x-large class="mr-3">mdi-account-edit-outline</v-icon>
          Edit Player Information - {{ player.firstName }}
          {{ player.lastName }}
          <v-spacer></v-spacer>
          <v-btn
            text
            style="position:absolute; z-index: 100; top:10px; right: 0"
            @click="closeDialog"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>

        <v-card-text>
          <v-form ref="formPlayer">
            <v-card class="mb-5">
              <v-card-title>
                <v-icon>mdi-account-circle-outline</v-icon>
                <span class="ml-3"> Name</span>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  :value="player.firstName"
                  :rules="nameRules"
                  :counter="30"
                  label="First Name"
                  @change="valueUpdated('firstName', $event)"
                >
                </v-text-field>

                <v-text-field
                  :value="player.lastName"
                  :rules="nameRules"
                  :counter="30"
                  label="Last Name"
                  @change="valueUpdated('lastName', $event)"
                >
                </v-text-field>

                <v-text-field
                  :value="player.screenName"
                  :rules="screenNameRules"
                  :counter="30"
                  label="CBS Sports Screen Name"
                  @change="valueUpdated('screenName', $event)"
                >
                </v-text-field>
              </v-card-text>
            </v-card>

            <v-card class="my-5">
              <v-card-title
                ><v-icon> mdi-map-marker-radius-outline</v-icon>
                <span class="ml-2"> Location</span>
                <v-spacer></v-spacer>
                <span class="grey--text">{{ latLng ? latLng : "" }}</span>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  ref="city"
                  :value="player.city"
                  :rules="cityRules"
                  label="City"
                  @change="
                    valueUpdated('city', $event);
                    getGeocode();
                  "
                ></v-text-field>

                <v-text-field
                  ref="state"
                  :value="player.state"
                  :rules="stateRules"
                  label="State"
                  @change="
                    valueUpdated('state', $event);
                    getGeocode();
                  "
                ></v-text-field>

                <v-text-field
                  ref="zipCode"
                  :value="player.zipCode"
                  :rules="zipCodeRules"
                  label="Zip Code"
                  @change="
                    valueUpdated('zipCode', $event);
                    getGeocode();
                  "
                ></v-text-field>

                <v-text-field
                  ref="crossroads"
                  :value="player.crossroads"
                  :rules="crossroadsRules"
                  label="Crossroads"
                  @change="
                    valueUpdated('crossroads', $event);
                    getGeocode();
                  "
                ></v-text-field>
              </v-card-text>
            </v-card>

            <v-card class="my-5">
              <v-card-title>
                <v-icon>mdi-information-outline</v-icon>
                <span class="ml-3">General Information</span>
              </v-card-title>
              <v-card-text>
                <v-select
                  label="Rookie Year"
                  :items="years"
                  :value="player.startYear"
                  :rules="rookieRules"
                  clearable
                  open-on-clear
                  @change="valueUpdated('startYear', $event)"
                >
                </v-select>

                <v-select
                  label="Favorite Team"
                  :items="teams"
                  item-value="teamId"
                  :value="player.favoriteTeamId"
                  clearable
                  open-on-clear
                  @change="valueUpdated('favoriteTeamId', $event)"
                >
                  <template v-slot:item="data">
                    {{ data.item.teamCity }} {{ data.item.teamName }}
                  </template>

                  <template slot="selection" slot-scope="data">
                    {{ data.item.teamCity }} {{ data.item.teamName }}
                  </template>
                </v-select>
              </v-card-text>
            </v-card>

            <v-card class="my-5">
              <v-card-title>
                <v-icon>mdi-note-text-outline</v-icon>
                <span class="ml-3"> Note</span>
              </v-card-title>
              <v-card-text>
                <v-textarea
                  label="Player Note"
                  auto-grow
                  :value="player.note"
                  @change="valueUpdated('note', $event)"
                >
                </v-textarea>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-container class="grey lighten-5">
            <v-btn
              class="ml-10 mr-7"
              color="success"
              :disabled="newValues.length < 1"
              @click="onSubmit"
            >
              <v-icon class="mr-3"> mdi-check</v-icon>
              Submit</v-btn
            >
            <v-btn color="error" @click="closeDialog"
              ><v-icon class="mr-3"> mdi-close</v-icon>Cancel</v-btn
            >
          </v-container>
        </v-card-actions>
      </v-container>
    </v-card>

    <v-dialog v-model="dialogExit" max-width="500px">
      <v-card>
        <v-card-title>
          <span>Unsaved Changes</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text
          >You have unsaved changes. Are you sure you want to exit?</v-card-text
        >
        <v-card-actions>
          <v-btn color="warning" text @click="exit">
            <v-icon class="mr-3"> mdi-exit-run</v-icon>
            Exit without saving
          </v-btn>
          <v-spacer> </v-spacer>
          <v-btn color="info" text @click="dialogExit = false">
            <v-icon class="mr-3"> mdi-arrow-left</v-icon>
            Go Back
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import EventService from "@/services/EventService.js";

export default {
  props: ["id"],
  data() {
    return {
      dialog: true,
      dialogExit: false,
      processing: false,

      newValues: [],
      teams: [],

      nameRules: [
        v => !!v || "Name is required",
        v => (v && v.length <= 30) || "Name must be less than 30 characters"
      ],
      screenNameRules: [
        v => v.length <= 30 || "Screen name must be less than 30 characters"
      ],
      cityRules: [
        v =>
          v == null || v.length <= 40 || "City must be less than 40 characters"
      ],
      stateRules: [
        v =>
          v == null || v.length <= 15 || "State must be less than 15 characters"
      ],
      zipCodeRules: [
        v =>
          v == null ||
          v.length == 0 ||
          v.length == 5 ||
          "Zip code should be 5 numbers"
      ],
      crossroadsRules: [
        v =>
          v == null ||
          v.length <= 75 ||
          "Crossroads should be less than 75 characters"
      ],
      rookieRules: [
        v => !!v || "Rookie Year is required",
        v =>
          (v && v.length == 0) ||
          (v && v.length == 4) ||
          "Rookie year should be a 4 digit year"
      ]
    };
  },
  computed: {
    years() {
      let currentYear = new Date().getFullYear();
      let list = [];
      for (let i = currentYear; i >= 2000; i--) {
        list.push(i.toString());
      }
      return list;
    },
    loading() {
      return this.player && this.teams && this.teams.length > 0 ? false : true;
    },
    player() {
      return this.$store.getters["player/getPlayerById"](this.id);
    },
    latLng() {
      let newLatLng = this.newValues.filter(
        lat => lat.key == "Lat" || lat.key == "Lng"
      );

      if (newLatLng && newLatLng.length > 0) {
        return newLatLng[0].value + ", " + newLatLng[1].value;
      }

      return this.player ? this.player.lat + ", " + this.player.lng : "";
    }
  },
  methods: {
    onSubmit() {
      //Validate Form if correclty filled out
      if (!this.$refs.formPlayer.validate()) {
        this.$store.commit("snackbar/SET_SNACK_PREDEFINED", "validation1");

        return;
      }

      this.processing = true;

      //Update Store
      for (let i = 0; i < this.newValues.length; i++) {
        this.$store.commit("player/UPDATE_PLAYER", {
          player: this.player,
          key: this.newValues[i].key,
          value: this.newValues[i].value ? this.newValues[i].value : null
        });
      }

      //Check to see if one of values is Favorite Team; if so, update some additional data in the store for Favorite Team data
      if (this.newValues.find(item => item.key == "favoriteTeamId")) {
        let id = this.newValues.find(item => item.key == "favoriteTeamId")
          .value;
        let team = this.teams.find(item => item.teamId == id);

        let fields = ["logo", "teamCity", "teamInitial", "teamName"];
        fields.forEach(item => {
          this.$store.commit("player/UPDATE_PLAYER", {
            player: this.player,
            key: item,
            value: id ? team[item] : ""
          });
        });
      }

      //Update DB
      var params = {};
      params.playerId = this.id;
      this.newValues.forEach(
        item => (params[item.key] = item.value ? item.value : "")
      );

      EventService.updatePlayerData(params)
        .then(response => {
          if (response.data == "Success") {
            this.$store.commit("snackbar/SET_SNACK_PREDEFINED", "success1");
            this.processing = false;
            this.exit();
          } else {
            this.processing = false;
            this.$store.commit("snackbar/SET_SNACK_PREDEFINED", "error1");
          }
        })
        .catch(error => {
          console.log("There was an error:", error.response);
          this.processing = false;
          this.$store.commit("snackbar/SET_SNACK_PREDEFINED", "error1");
        });
    },

    valueUpdated(key, value) {
      let alreadyModifiedIndex = this.newValues.findIndex(
        values => values.key == key
      );

      if (alreadyModifiedIndex != -1) {
        this.newValues.splice(alreadyModifiedIndex, 1);
      }

      this.newValues.push({ key: key, value: value });
    },
    getGeocode() {
      let address =
        this.$refs.crossroads._data.lazyValue +
        "+" +
        this.$refs.city._data.lazyValue +
        "+" +
        this.$refs.state._data.lazyValue +
        "+" +
        this.$refs.zipCode._data.lazyValue;

      let url =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        address +
        "&key=AIzaSyBtGR8MRRi7IY8hs743ThHlFRW5xFYnQsw";

      EventService.getGeocode(url).then(response => {
        if (response.data.results && response.data.results.length > 0) {
          this.valueUpdated(
            "Lat",
            response.data.results[0].geometry.location.lat
          );
          this.valueUpdated(
            "Lng",
            response.data.results[0].geometry.location.lng
          );
        } else {
          this.valueUpdated("Lat", "");
          this.valueUpdated("Lng", "");
        }
      });
    },
    closeDialog() {
      if (this.newValues.length > 0) {
        this.dialogExit = true;
      } else {
        this.exit();
      }
    },
    exit() {
      this.$emit("close");
    }
  },
  created() {
    this.$store.dispatch("player/fetchPlayer", this.id);

    EventService.getTeams()
      .then(response => {
        this.teams = response.data;
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });
  }
};
</script>

<style scoped>
.processing {
  position: fixed;
  left: 50%;
  top: 35%;
  z-index: 100;
}
</style>
