<template>
  <v-container fluid class="grey lighten-5">
    <hr />

    <hr />
    <v-card>
      <v-card-title>
        <h1 class="mx-auto display-1">
          <img src="@/assets/Super-Bowl-Trophy-1.png" style="height:50px" />
          <span class="mx-5">League Champions </span>
          <img src="@/assets/Super-Bowl-Trophy-1.png" style="height:50px" />
        </h1>
      </v-card-title>

      <v-card-text>
        <v-data-table
          id="champions"
          dense
          :headers="headers"
          :items="champions_clean"
          item-key="index"
          class="elevation-1"
          :loading="loading"
          loading-text="Loading... Please wait"
          @click:row="playerSelected"
        >
          <template v-slot:item.championships="{ item }">
            <div style="height:30px">
              <img
                v-for="(champ, i) in filteredChampionships(item.championships)"
                :key="i"
                src="@/assets/Super-Bowl-Trophy-1.png"
                class="pt-1 "
                style="height: 100%; "
              />
            </div>
          </template>
        </v-data-table> </v-card-text
    ></v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  title: "AZFBF - Champions",
  data() {
    return {
      headers: [
        { value: "championships" },
        {
          text: "Full Name",
          value: "fullName",
          align: "start"
        },
        { text: "Season", value: "season" },
        { text: "Record", value: "record" },
        { text: "Percent", value: "official_pickCorrectPercentage" }
      ]
    };
  },
  computed: {
    ...mapState({ champions: state => state.champions.champions }),
    loading() {
      return this.champions && this.champions.length ? false : true;
    },
    champions_clean() {
      var champions = this.champions;
      let result = [];

      for (let i = 0; i < champions.length; i++) {
        result[i] = {
          playerId: champions[i].playerId,
          championships: champions[i].championships,
          fullName: champions[i].firstName + " " + champions[i].lastName,
          season: champions[i].season,
          record: !champions[i].official_pickCorrect
            ? ""
            : champions[i].official_pickCorrect +
              " - " +
              champions[i].official_pickIncorrect,
          official_pickCorrectPercentage: !champions[i]
            .official_pickCorrectPercentage
            ? ""
            : (champions[i].official_pickCorrectPercentage * 100).toFixed(1) +
              "%"
        };
      }

      return result;
    }
  },

  methods: {
    playerSelected(value) {
      this.$router.push({ name: "Player", params: { id: value.playerId } });
    },
    filteredChampionships(championships) {
      if (championships === 1) {
        return 0;
      } else {
        return championships;
      }
    }
  },
  created() {
    this.$store.dispatch("champions/fetchChampions");
  }
};
</script>

<style>
#champions {
  cursor: pointer;
}
</style>
