import EventService from "@/services/EventService.js";

export const namespaced = true;

export const state = {
  champions: [],
  promise: null
};

export const mutations = {
  SET_CHAMPIONS(state, champions) {
    state.champions = champions;
  },
  SET_PROMISE(state, data) {
    state.promise = data;
  },
  CLEAR_PROMISE(state, data) {
    state.promise = data;
  }
};

export const actions = {
  fetchChampions({ commit }) {
    if (state.champions[0]) {
      return;
    }

    // If the promise is set another request is already getting the data.  return the first requests promise and exit
    if (state.promise) {
      return state.promise;
    }

    //Pull Data
    var promise = EventService.getChampions()
      .then(response => {
        commit("SET_CHAMPIONS", response.data);
        commit("CLEAR_PROMISE", null);
      })
      .catch(error => {
        console.log("There was an error: " + error.response);
        commit("CLEAR_PROMISE", null);
      });

    //While data is being async gathered via Axios we set this so that subsequent requests will exit above before trying to fetch data multiple times
    commit("SET_PROMISE", promise);

    return promise;
  }
};

export const getters = {
  getChampionshipsById: state => id => {
    let x = state.champions.filter(player => player.playerId === id);
    return x;
  }
};
