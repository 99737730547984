<template>
  <v-card :loading="!player">
    <div v-if="!player">
      Loading...
    </div>
    <!-- <div v-if="!$store.state.player.loading"> -->
    <v-container v-if="player">
      <v-row>
        <v-col cols="3" align="middle">
          <img :src="logoURL" style="height:85px"
        /></v-col>
        <v-col cols="6" class="mx-auto">
          <v-card-title class="display-3 ">
            <span class="mx-auto" style="white-space:nowrap; overflow: hidden;">
              {{ player.firstName }} {{ player.lastName }}
            </span>
            <v-btn
              v-if="loggedIn"
              @click="$emit('edit')"
              icon
              x-large
              style="position:absolute; top:8px; right: 8px"
              ><v-icon>mdi-account-edit</v-icon></v-btn
            >
          </v-card-title>
        </v-col>
        <v-col cols="3" align="middle">
          <img
            v-for="champ in player.championships"
            :key="champ"
            src="../assets/Super-Bowl-Trophy-1.png"
            style="height: 85px;"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { authComputed } from "@/store/helpers.js";

export default {
  props: ["id"],

  computed: {
    ...authComputed,
    player() {
      return this.$store.getters["player/getPlayerById"](this.id);
    },
    logoURL() {
      if (this.player.logo) {
        return require("../assets/TeamLogo/" + this.player.logo);
      } else {
        return require("../assets/TeamLogo/0.png");
      }
    }
  },
  created() {
    this.$store.dispatch("player/fetchPlayer", this.id);
  }
};
</script>

<style></style>
