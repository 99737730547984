<template>
  <v-card class=" pr-3">
    <v-progress-circular
      v-if="loading"
      color="primary"
      indeterminate
      :width="3"
    ></v-progress-circular>
    <v-card-title>Awards</v-card-title>
    <v-timeline
      :dense="true"
      class="pl-0"
      v-if="!loading && !timelineFilter.length == 0"
      align-top
    >
      <v-timeline-item
        v-for="(win, i) in timelineFilter"
        :key="i"
        class="pl-0"
        :icon="win.icon"
        :small="win.small"
        :large="win.large"
        :color="win.color"
      >
        <v-card>
          <v-container class="pb-0">
            <v-row no-gutters>
              <v-col cols="3" class="pl-1">
                <img :src="image(win.type)" style="height:60px" />
              </v-col>
              <v-col cols="9">
                <v-row>
                  <v-col class="pb-0">
                    <h3 class="title py-0">
                      {{ win.title }}
                    </h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <p class="body-1 my-0">{{ win.subtitle }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
import _ from "lodash";

export default {
  props: ["id"],
  data() {
    return {
      loading: true
    };
  },

  computed: {
    weekWinner() {
      return this.$store.getters["playerStat/getWeekWinnerById"](this.id);
    },

    championships() {
      return this.$store.getters["champions/getChampionshipsById"](this.id);
    },

    player() {
      return this.$store.getters["player/getPlayerById"](this.id);
    },

    timelineFilter() {
      var timeline = [];

      // Add Weekly Wins
      this.weekWinner.forEach(element => {
        timeline.push({
          title: "Win " + element.season + ", Week " + element.week,
          subtitle: element.pickCorrect + " Points",
          season: element.season,
          week: element.week,
          icon: "mdi-star-outline",
          small: true,
          type: "winWeek"
        });
      });

      // Add Champoinships
      this.championships.forEach(element => {
        timeline.push({
          title: element.season + " Champion",
          subtitle:
            element.official_pickCorrect +
            " - " +
            element.official_pickIncorrect,
          season: element.season,
          week: 100,
          icon: "mdi-trophy-variant-outline",
          large: true,
          type: "champion"
        });
      });

      // Add Rookie Addiversary
      timeline.push({
        title: "Rookie Season",
        subtitle: this.player.startYear,
        season: this.player.startYear,
        week: 0,
        icon: "mdi-account-circle-outline",
        color: "green",
        type: "rookie"
      });

      // Add additional Anniversaries
      var start = Number(this.player.startYear) + 5;
      for (let index = start; index < 2020; index += 5) {
        timeline.push({
          title:
            this.number_suffix(index - Number(this.player.startYear)) +
            " Anniversary",
          subtitle: index,
          season: index,
          week: 0,
          icon: "mdi-sign-direction",
          color: "green",
          type: "rookie"
        });
      }

      return _.orderBy(timeline, ["season", "week"], ["desc", "desc"]);
    }
  },
  methods: {
    number_suffix(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },

    image(type) {
      if (type == "winWeek") return require("@/assets/Medal.png");

      if (type == "champion")
        return require("@/assets/Super-Bowl-Trophy-1.png");

      if (type == "rookie") return require("@/assets/1st-anniversary.png");

      return;
    },
    fetchData() {
      Promise.all([
        this.$store.dispatch("champions/fetchChampions"),
        this.$store.dispatch("playerStat/fetchWeekWinner", this.id),
        this.$store.dispatch("player/fetchPlayer", this.id)
      ]).then(() => (this.loading = false));
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    "$route.params.id": function() {
      this.loading = true;
      this.fetchData();
    }
  }
};
</script>

<style scoped></style>
