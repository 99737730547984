<template>
  <v-card>
    <v-card-title>Season Results</v-card-title>
    <v-card-text class="pb-0 mb-0">
      <v-row>
        <v-col cols="8" align="middle">
          AZFBF Results
        </v-col>
        <v-col align="middle">Actual Picks</v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="8">
          <v-data-table
            :loading="loading"
            :headers="headerAZFBF"
            :items="seasonStat_clean"
            item-key="Season"
            hide-default-footer
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            class="elevation-1"
          >
          </v-data-table>
        </v-col>
        <v-col cols="4">
          <v-data-table
            :loading="loading"
            :headers="headerMe"
            :items="seasonStat_clean"
            item-key="Season"
            hide-default-footer
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            class="elevation-1"
          >
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="pageCount > 1">
        <v-col>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-col>
        <!-- <v-col cols="3" class="ml-8"
          ><v-text-field
            :value="itemsPerPage"
            label="Rows per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field
        ></v-col> -->
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

export default {
  props: { id: { required: true } },
  data() {
    return {
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headerAZFBF: [
        { text: "Season", align: "start", value: "season" },
        { text: "Record", value: "recordAZFBF" },
        { text: "Percentile", value: "winPercentAZFBF" },
        { text: "Rank", value: "rankAZFBF" }
      ],
      headerMe: [
        { text: "Record", value: "record" },
        { text: "Percentile", value: "winPercent" }
      ]
    };
  },
  computed: {
    ...mapState({ seasonStat: state => state.playerStat.seasonStat }),

    // loading() {
    //   return this.seasonStat && this.seasonStat.length > 0 ? false : true;
    // },
    seasonStat_clean() {
      var stats = this.seasonStat;
      let result = [];

      for (let i = 0; i < stats.length; i++) {
        result[i] = {
          playerID: stats[i].playerId,
          season: stats[i].season,
          recordAZFBF:
            stats[i].official_pickCorrect +
            " - " +
            stats[i].official_pickIncorrect,
          winPercentAZFBF:
            (stats[i].official_pickCorrectPercentage * 100).toFixed(1) + "%",
          rankAZFBF: stats[i].official_playerRank,
          record: stats[i].pickCorrect + " - " + stats[i].pickIncorrect,
          winPercent: (stats[i].pickCorrectPercentage * 100).toFixed(1) + "%"
        };
      }

      return _.orderBy(result, "season", "desc");
    }
  },
  methods: {
    fetchStats() {
      this.$store
        .dispatch("playerStat/fetchPlayerSeasonStat", this.id)
        .then(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.fetchStats();
  },
  watch: {
    "$route.params.id": function() {
      this.loading = true;
      this.fetchStats();
    }
  }
};
</script>

<style></style>
