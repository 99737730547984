<template>
  <GoogleMapLoader
    :mapConfig="mapConfig"
    apiKey="AIzaSyBtGR8MRRi7IY8hs743ThHlFRW5xFYnQsw"
  >
    <template v-slot="{ google, map }">
      <GoogleMapMarker
        v-for="marker in markers"
        :key="marker.playerId"
        :marker="marker"
        :google="google"
        :map="map"
      />
      <!-- <GoogleMapLine
        v-for="line in lines"
        :key="line.id"
        v-model:path="line.path"
        :google="google"
        :map="map"
      />-->
    </template>
  </GoogleMapLoader>
</template>

<script>
import GoogleMapLoader from "./maps/GoogleMapLoader";
import GoogleMapMarker from "./maps/GoogleMapMarker";
// import GoogleMapLine from "./maps/GoogleMapLine";

import { mapSettings } from "@/constants/mapSettings";

import EventService from "@/services/EventService.js";

export default {
  components: {
    GoogleMapLoader,
    GoogleMapMarker
    // GoogleMapLine
  },

  data() {
    return {
      markers: []
    };
  },

  computed: {
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter
      };
    },

    mapCenter() {
      //   return this.markers[1].position;
      return { lat: 45, lng: -150 };
    }
  },
  created() {
    EventService.getMapMarkers() // <--- Send the prop id to our EventService
      .then(response => {
        // console.log(response.data);
        this.markers = response.data;
      })
      .catch(error => {
        console.log("There was an error:", error.response);
      });
  }
};
</script>
