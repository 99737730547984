<template>
  <div>
    <v-card :loading="loading">
      <span v-if="loading"> Loading Trends ... </span>
      <v-card-title>Pick Trends</v-card-title>
      <v-card-subtitle>Data starts in 2009</v-card-subtitle>
      <v-card-text v-if="!loading">
        <v-row>
          <!-- Picked Home Team Widget -->
          <v-col cols="12" lg="6">
            <v-card height="100%">
              <v-card-title>Picked Home Team</v-card-title>
              <v-card-text v-if="trends_HomeData[0].me_homePick">
                <v-row
                  ><v-col>
                    <strong>{{ player.firstName }}:</strong> <br />
                    {{ displayPercentage(trends_HomeData[0].me_percent) }}
                    %
                    <br />
                    {{ displayNumber(trends_HomeData[0].me_homePick) }}
                    of
                    {{ displayNumber(trends_HomeData[0].me_games) }}
                  </v-col>

                  <v-col>
                    <strong> League: </strong><br />

                    {{ displayPercentage(trends_HomeData[0].league_percent) }}
                    %
                    <br />
                    {{ displayNumber(trends_HomeData[0].league_homePick) }}
                    of
                    {{ displayNumber(trends_HomeData[0].league_games) }}
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else>
                {{ player.firstName }} does not have picks
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Picked Favorite Team Widget -->
          <v-col cols="12" lg="6">
            <v-card height="100%">
              <v-card-title>Picked Favorite Team</v-card-title>
              <v-card-subtitle
                v-if="pickedFavorite[0] && trends_HomeData[0].me_homePick"
                >{{ pickedFavorite[0].team }}</v-card-subtitle
              >
              <v-card-text
                v-if="pickedFavorite[0] && trends_HomeData[0].me_homePick"
              >
                <v-row>
                  <v-col>
                    <strong>{{ player.firstName }}:</strong> <br />
                    {{ displayPercentage(pickedFavorite[0].me_percentPicked) }}
                    %
                    <br />
                    {{ displayNumber(pickedFavorite[0].me_timesPicked) }} of
                    {{ displayNumber(pickedFavorite[0].me_total) }}
                  </v-col>
                  <v-col>
                    <strong>League:</strong> <br />
                    {{
                      displayPercentage(pickedFavorite[0].league_percentPicked)
                    }}
                    %
                    <br />
                    {{ displayNumber(pickedFavorite[0].league_timesPicked) }}
                    of
                    {{ displayNumber(pickedFavorite[0].league_total) }}
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else-if="pickedFavorite[0]"
                >{{ player.firstName }} does not have picks
              </v-card-text>
              <v-card-text v-else>
                {{ player.firstName }} does not have a favorite
                team</v-card-text
              >
            </v-card>
          </v-col>

          <!-- Most Picked Teams Widget -->
          <v-col cols="12">
            <v-card>
              <v-card-title
                >Most Picked Teams
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :loading="loading"
                :headers="pickedMost.headers"
                :items="pickedMost.stats"
                :items-per-page="5"
                item-key="Team"
                :search="search"
                class="elevation-1"
              >
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import EventService from "../services/EventService.js";

export default {
  props: ["id"],
  data() {
    return {
      loading: true,
      search: "",
      trends_HomeData: [],
      trends_PickFrequencyData: []
    };
  },
  computed: {
    player() {
      return this.$store.getters["player/getPlayerById"](this.id);
    },
    pickedFavorite() {
      return this.trends_PickFrequencyData.filter(
        team => team.teamId == this.player.favoriteTeamId
      );
    },
    pickedMost() {
      let cleanStats = this.trends_PickFrequencyData.map(team => {
        return {
          team: team.team,
          me_picks:
            this.displayNumber(team.me_timesPicked) +
            " of " +
            this.displayNumber(team.me_total),
          me_percent: this.displayPercentage(team.me_percentPicked),
          league_picks:
            this.displayNumber(team.league_timesPicked) +
            " of " +
            this.displayNumber(team.league_total),
          league_percent: this.displayPercentage(team.league_percentPicked)
        };
      });

      return {
        headers: [
          {
            text: "Team",
            align: "start",
            sortable: false,
            value: "team"
          },
          { text: "Picks", value: "me_picks" },
          { text: "Percentile", value: "me_percent" },
          { text: "Picks", value: "league_picks" },
          { text: "Percentile", value: "league_percent" }
        ],
        stats: cleanStats
      };
    }
  },
  methods: {
    displayPercentage(value) {
      return Math.round(value * 1000) / 10;
    },
    displayNumber(value) {
      return value.toLocaleString("en-US");
    },
    async fetchStats() {
      Promise.all([
        EventService.getPickedHome(this.id, 2000, 2030), //Get data on when home team is picked
        EventService.getPickFrequency(this.id, 2000, 2030), //Get data on pick frequency
        this.$store.dispatch("player/fetchPlayer", this.id) //Ensure player data is in store that player computer property uses
      ])
        .then(([{ data: pickedHome }, { data: pickFrequency }]) => {
          this.trends_HomeData = pickedHome;
          this.trends_PickFrequencyData = pickFrequency;
        })
        .then(() => {
          this.loading = false;
        })
        .catch(error => console.log("An error occured getting data: " + error));
    }
  },
  created() {
    this.fetchStats();
  },
  watch: {
    "$route.params.id": function() {
      this.loading = true;
      this.fetchStats();
    }
  }
};
</script>

<style></style>
