<template>
  <div style="margin: 1.5rem">
    <b> Step 1:</b> Import data from csv files.
    <ul>
      <li>Use mySQLWorkbench</li>
      <li>Import Schdule to temp_Schedule</li>
      <li>
        Import picks to Temp_Picks(make sure data is mapped to the correct
        columns - especially last columns)
      </li>
    </ul>

    <hr />
    <div style="margin:  1.5rem">
      <input
        type="submit"
        value="Update Default Inputs from temp_schedule data"
        @click="defaultInputs()"
        style="background: #2471ff; color: #fff"
      />
    </div>

    <b>Step 2:</b> Input information used to insert schedule and picks from temp
    tables to Schedule & Picks tables

    <div class="preference">
      <label for="Season_Schedule">Season</label>
      <input
        type="text"
        v-model="season"
        id="Season_Schedule"
        name="Season_Schedule"
      />
    </div>
    <div class="preference">
      <label for="Week_Schedule">Week</label>
      <input
        type="text"
        v-model="week"
        id="Week_Schedule"
        name="Week_Schedule"
      />
    </div>

    <hr />
    <b>Step 3:</b> Move data from temp_schedule to schedule.
    <ul>
      <li>
        This transforms data from values like the team initial (Ari, Bal, etc)
        in the temp table coming from CBSSports to the team ID in AZFBF.com
      </li>
    </ul>

    <div class="preference">
      <label for="Schedule_Away">MNF Away Team Initial</label>
      <input
        type="text"
        v-model="awayTeam"
        id="Schedule_Away"
        name="Schedule_Away"
      />
    </div>

    <div class="preference">
      <label for="Schedule_Home">MNF Home Team Initial (ex. ARI)</label>
      <input
        type="text"
        v-model="homeTeam"
        id="Schedule_Home"
        name="Schedule_Home"
      />
    </div>

    <div style="margin:  1.5rem">
      <input
        type="submit"
        value="Insert Schedule"
        @click="importSchedule()"
        style="background: #2471ff; color: #fff"
      />
    </div>

    <hr />

    <b>Step 4:</b> Move data from temp_picks to picks
    <ul>
      <li>Validate each person exists</li>
      <li>Validate schedule for the Season & Week has already been added</li>
      <li>Insert Picks</li>
      <li>Refresh Picks_All table for the specific season</li>
    </ul>
    <div style="margin:  1.5rem">
      <input
        type="submit"
        value="Insert Picks"
        @click="importPicks()"
        style="background: #2471ff; color: #fff"
      />
    </div>

    <hr />
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";

export default {
  data() {
    return {
      season: new Date().getFullYear(),
      week: "",
      awayTeam: "",
      homeTeam: ""
    };
  },
  methods: {
    async importPicks() {
      //*********************** Perform Validations *****************************
      try {
        //validate season and week data entry inputs are in correct range of values
        if (!this.import_picks_validateInput()) {
          return; //Validation failed, exit
        }

        //Validate all users exisit in the database
        if (!(await this.import_picks_validateUsers())) {
          return false; //Validation failed, exit
        }

        //Validate Schedule exists for Season and Week input
        if (!(await this.import_picks_validateSchedule())) {
          //Validation failed, exit
          return false;
        }
      } catch (error) {
        console.log(error);
      }

      //*********************** Insert Picks *****************************
      try {
        const result_insertPick = await EventService.import_picks_insert(
          this.season,
          this.week
        );

        alert(
          `For ${this.season} week ${this.week} there are ${result_insertPick.data[0].ct} picks entered, ${result_insertPick.data[1].ct} with missed picks and ${result_insertPick.data[2].ct} MNF records added.`
        );
      } catch (error) {
        console.log(error);
      }
    },

    import_picks_validateInput() {
      //Validate Season is this calendar year or last
      let currentYear = new Date().getFullYear();
      if (!(this.season >= currentYear - 1 && this.season <= currentYear)) {
        alert(
          "Validation Failed - Season must be current calendar year or prior calendar year"
        );
        return false;
      }

      //Validate Week is in range of 1 to 18
      if (!(this.week >= 1 && this.week <= 18)) {
        alert("Validation Failed - Week must be between 1 and 18");
        return false;
      }

      //All validation successful, return true
      return true;
    },

    import_picks_validateUsers() {
      //Check to see that all users in the temp_picks table have a matching entry in players tables
      return EventService.import_picks_validateUsers().then(response => {
        if (response.data.length == 0) {
          return Promise.resolve(true);
        } else {
          //Validation Failed
          alert(
            "Players with picks missing from Players table\nUpdate Player Screen Name or add new player\n\n" +
              JSON.stringify(response.data)
          );
          return Promise.reject(
            "Players need updated: " + JSON.stringify(response.data)
          );
        }
      });
    },

    import_picks_validateSchedule() {
      //Check the schedule table to make sure that the scehdule exists for the week we are trying to insert picks for
      // return EventService.importValidationPicksSchedule(
      return EventService.import_picks_validateSchedule(
        this.season,
        this.week
      ).then(response => {
        if (response.data.length == 0) {
          //Validation Failed - No Schedule found
          alert(
            "No games have been added to the schedule for week " +
              this.week +
              " of the " +
              this.season +
              " season.  \n\nYou need to update the schedule before you insert picks."
          );
          return Promise.reject("No games found");
        } else {
          //Validation Successful
          return Promise.resolve(true);
        }
      });
    },

    importSchedule() {
      this.validateDataInput()
        .then(() => {
          return this.import_insertSchedule();
        })
        .then(() => {
          alert("Schedule Added");
        })
        .catch(error => alert(error));
    },

    import_insertSchedule() {
      return EventService.import_insertSchedule(
        this.season,
        this.week,
        this.homeTeam,
        this.awayTeam
      )
        .then(response => {
          //This just makes sure something was insterted/updated.  Later could do more percise validation of the number of games matching.  Current user doesn't have to enter number of games for this.
          if (response.data[0] > 0 && response.data[1] > 0) {
            return Promise.resolve(true);
          } else {
            return Promise.reject(
              "Either schedule was not inserted or tie break game was not updated"
            );
          }
        })
        .catch(error => {
          // console.log("There was an error:", error.response);
          return Promise.reject(error);
        });
    },

    validateDataInput() {
      //Validate all fields have data entered in them
      if (this.season.length == 0)
        return Promise.reject("Missing Season Input");
      if (this.week.length == 0) return Promise.reject("Missing Week Input");
      if (this.awayTeam.length == 0)
        return Promise.reject("Missing Away Team Input");
      if (this.homeTeam.length == 0)
        return Promise.reject("Missing Home Team Input");

      return EventService.importValidateDataInput(this.homeTeam, this.awayTeam)
        .then(response => {
          // Make sure there are results in the temp_schedule table
          if (response.data[0].length == 0)
            return Promise.reject(
              "temp_schedule is empty.  Data should already be imported into table"
            );

          // Make sure there is only one Season + Week in the temp table
          if (response.data[0].length > 1)
            return Promise.reject(
              "There is more than one season/week in temp_schedule table.  Only one week should exist."
            );

          //Validate that Season and Week in the database match the users Input
          if (
            response.data[0][0].season != this.season ||
            response.data[0][0].week != this.week
          )
            return Promise.reject(
              "The Season or Week entered do not match what is in temp_schedule"
            );

          // //Validate that there is a game with the teams the user specified for the tie break MNF game.
          if (response.data[1].length == 0)
            return Promise.reject("No games match your MNF input");

          if (response.data[1].length > 1)
            return Promise.reject(
              "Multiple games match your MNF input.  Check temp_schedule for duplicate imports"
            );

          //no errors found.  Validation passes
          return Promise.resolve(true);
        })
        .catch(error => {
          // console.log("There was an error:", error);
          return Promise.reject(error);
        });
    },
    
    defaultInputs () {
      return new Promise((resolve, reject) => {
        const sqlSchedule = { sql: `select season, week from temp_schedule group by season, week;` };
        const sqlMNFTeams = { sql: `select homeTeamInitial, awayTeamInitial 
                                      from temp_schedule
                                      order by game_insert_order desc
                                      limit 1;` };
        Promise.all([
          EventService.customQuery(sqlSchedule),
          EventService.customQuery(sqlMNFTeams)
        ])
          .then(([{ data: schedule }, { data: MNF }]) => {
            this.season = schedule[0].season;
            this.week = schedule[0].week;
            this.awayTeam = MNF[0].awayTeamInitial;
            this.homeTeam = MNF[0].homeTeamInitial;  
            resolve();
          })
          .catch((error) => reject(error));
      });
    }
  },

  created() {
    this.defaultInputs();
  }
  // created: function() {
  //   defaultInputs();
  // }

};
</script>

<style scoped>
.preference {
  display: flex;
  justify-content: space-between;
  width: 35%;
  margin: 0.5rem 1.5rem;
}
</style>
