<template>
  <div>
    <div
      style="display:flex; flex-flow: row wrap; justify-content: space-around;"
    >
      <div v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <stat-widget
        v-else
        v-for="(stat, i) in statWidget"
        :key="i"
        :title="stat[i].title"
        :tooltip="stat[i].tooltip"
        :stats="stat"
      />
    </div>

    <Champions />

    <v-container fluid class="grey lighten-5">
      <v-card class="elevation-5 pa-1">
        <Map class="champion-map" />
      </v-card>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import EventService from "@/services/EventService.js";
import SQL from "@/services/SQL.js";
import Champions from "@/components/Champions.vue";
import Map from "@/components/ChampionsMap.vue";
import StatWidget from "../components/stats/StatWidget.vue";

export default {
  name: "Home",
  components: {
    Champions,
    Map,
    StatWidget
  },
  data() {
    return {
      loading: true,
      statWidget: []
    };
  },
  created() {
    Promise.all([
      EventService.customQuery(SQL.bestSeasonAllTime()),
      EventService.customQuery(SQL.pointsAllTime()),
      EventService.customQuery(SQL.careerPickPercentage())
    ]).then(response => {
      this.statWidget.push(response[0].data);
      this.statWidget.push(response[1].data);
      this.statWidget.push(response[2].data);
      // console.log(this.statWidget);
      this.loading = false;
    });
  }
};
</script>

<style scoped>
.champion-map {
  height: 350px;
}
</style>
