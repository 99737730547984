import { render, staticRenderFns } from "./StatWidget.vue?vue&type=template&id=a364679c&scoped=true&"
import script from "./StatWidget.vue?vue&type=script&lang=js&"
export * from "./StatWidget.vue?vue&type=script&lang=js&"
import style0 from "./StatWidget.vue?vue&type=style&index=0&id=a364679c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a364679c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VCardTitle,VCarousel,VCarouselItem,VSheet,VTooltip})
