<template>
  <v-card>
    <input ref="test" />
    <v-toolbar flat dark color="indigo darken-4">
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>

      <v-toolbar-title>AZ Football Fanatics</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-form>
        <v-autocomplete
          ref="search"
          v-model="searchResult"
          :search-input.sync="searchString"
          class="mt-8 mr-3"
          label="Player Search (press /)"
          prepend-inner-icon="mdi-account-search-outline"
          :items="players"
          item-text="fullName"
          item-value="playerId"
          menu-props="closeOnClick"
          auto-select-first
          single-line
          solo-inverted
          dense
          @change="gotoPlayer"
          @keydown.enter.native.prevent
        ></v-autocomplete>
      </v-form>

      <v-menu bottom left :offset-x="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon :color="loggedIn ? 'red' : 'white'"
              >mdi-dots-vertical</v-icon
            >
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in menuItemsFiltered"
            :key="i"
            @click="menuAction(item.action, item.to, item.param)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }} </v-icon></v-list-item-icon
            >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- <v-btn icon class="mr-1"
        ><v-icon @click="$router.push('/login')"
          >mdi-dots-vertical</v-icon
        ></v-btn
      > -->

      <template v-slot:extension>
        <v-tabs show-arrows v-model="tab" align-with-title>
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab
            v-for="item in items"
            :key="item.tab"
            :to="{ name: item.page }"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
  </v-card>
</template>

<script>
import EventService from "@/services/EventService.js";
import { authComputed } from "@/store/helpers.js";
import { Auth } from "aws-amplify";

export default {
  data() {
    return {
      tab: null,
      items: [
        { tab: "Champions", page: "Home" },
        { tab: "Players", page: "Players" },
        { tab: "News", page: "News" },
        { tab: "Games", page: "GameNFL" }
      ],
      players: [],
      searchResult: null,
      searchString: " ",

      menuItems: {
        authenticated: [
          {
            title: "Champions",
            action: "router",
            to: "Home",
            icon: "mdi-home-outline"
          },
          {
            title: "Import Stats",
            action: "router",
            to: "import-picks",
            icon: "mdi-calendar-edit"
          },
          {
            title: "AZFBF v1.0",
            action: "openWindow",
            to: "http://azfbf.epizy.com/Home.php",
            icon: "mdi-share-outline"
          },
          {
            title: "Add News Article",
            action: "router",
            to: "NewsNew",
            param: { editing: false, article: {} },
            icon: "mdi-note-text-outline"
          },
          // {
          //   title: "NFL Divisions Game",
          //   action: "router",
          //   to: "GameNFL",
          //   icon: "mdi-note-text-outline"
          // },
          // {
          //   title: "AZFBF v2.0",
          //   action: "openWindow",
          //   to: "http://azfbf.s3-website-us-west-2.amazonaws.com",
          //   icon: "mdi-share-outline"
          // },

          {
            title: "Logout",
            action: "function",
            to: "logout",
            icon: "mdi-logout-variant"
          }
        ],
        unauthenticated: [
          {
            title: "Login",
            action: "router",
            to: "Login",
            icon: "mdi-login-variant"
          },
          {
            title: "Champions",
            action: "router",
            to: "Home",
            icon: "mdi-home-outline"
          }
        ]
      }
    };
  },
  computed: {
    ...authComputed,
    menuItemsFiltered() {
      return this.loggedIn
        ? this.menuItems.authenticated
        : this.menuItems.unauthenticated;
    }
  },

  methods: {
    menuAction(action, to, param) {
      if (action == "router") {
        this.$router.push({
          name: to,
          params: param
        });
        // this.$router.push({ name: to }).catch(() => {});
      } else if (action == "function") {
        this[to]();
      } else if (action == "dispatch") {
        this.$store.dispatch(to);
      } else if (action == "openWindow") {
        window.open(to);
      }
    },

    async logout() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log(error.message);
      }
    },

    gotoPlayer(e) {
      if (e == this.$route.params.id || e == null) {
        return;
      }

      this.$router.push({ name: "Player", params: { id: e } });

      this.$nextTick(() => {
        this.searchString = "";
        this.searchResult = null;
        this.$refs.search.blur();
      });
    }
  },

  created() {
    EventService.getPlayersAll()
      .then(response => {
        // console.log(response.data);
        this.players = response.data; //Set the data returned from api to our data array
      })
      .catch(error => {
        console.log("There was an error: " + error.response);
      });

    //  Listen to event emited to put focus on player search
    this.$root.$on("search", () => this.$refs.search.$refs.input.focus());
  }
};
</script>
